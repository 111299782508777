<template>
    <div class="p-6 text-center">
        <div class="text-xl line-height-4 mb-4">
            {{ $t('Submit survey now.') }}
            <br>
            {{ $t('Attention: A change is no longer possible afterwards.') }}
        </div>
        <Button
            :label="$t('Submit survey')"
            class="cursor-pointer"
            @click="finishSurvey"
        />
    </div>
</template>

<script>
    import Button from 'primevue/button';
    import UpdateForm from "@/components/data/surveryPart/UpdateForm";

    export default {
        components: {
            Button,
            UpdateForm
        },
        props: {
            survey: {
                type: [Object],
                required: true,
            }
        },
        methods: {
            finishSurvey() {
                this.$confirm.require({
                    header: this.$t('Confirm'),
                    message: this.$t('Are you sure you want the survey to be submitted?'),
                    acceptLabel: this.$t('Yes'),
                    rejectLabel: this.$t('Abort'),
                    accept: () => {
                        this.$store.commit('incrementLoadingIndex');
                        this.$http.patch(
                            this.survey['@id'].substr(1),
                            JSON.stringify({status: 'finished'}),
                            {
                                headers: {
                                    'Content-Type': 'application/json'
                                }
                            }
                        ).then(response => {
                            this.$store.commit('decrementLoadingIndex');
                            this.$toast.add({
                                severity: 'success',
                                summary: this.$t('Success'),
                                detail: this.$t('Survey was successfully submitted.'),
                                life: 3000
                            });
                            this.$router.push('/surveys');
                        }, response => {
                            this.$store.commit('decrementLoadingIndex');
                            this.$toast.add({
                                severity: 'error',
                                summary: this.$t('Error'),
                                detail: this.$t('Error while submitting survey'),
                                life: 3000
                            });
                        });
                    },
                    reject: () => {
                        //callback to execute when user rejects the action
                    }
                });
            }
        }
    }
</script>
